html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    color: #1A1A1A;
}

h1, h2, h3, h4, h5, h6 {
    clear: both;
    font-family: 'Montserrat', sans-serif;
}

.row {
    width: 100%;
    font-family: 'Roboto Slab', serif;
    font-size: 19px;
    line-height: 33px;
}

.row-big-card .cont {
    box-shadow: 0 0 30px 0 rgba(0,0,0,.15);
    margin: 30px auto;
    width: calc(100% - 40px);
    max-width: 1760px;
    padding: 0;
    position: relative;
}

a.card-link {
    color: #1A1A1A;
    display: block;
    text-decoration: none;
}

.card-img {
    padding-left: 45%;
    position: relative;
}

.row-big-card .cont .card-inner {
    padding: 40px 20px 0 60px;
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    bottom: 0;
    background: white;
    width: 40%;
}

.card-img div {
    display: block;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 8% 100%);
    transition: all .2s ease-in-out;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    padding-bottom: 64%;
}

.card-inner h3 {
    font-size: 76px;
    line-height: 82px;
    letter-spacing: -1px;
    margin: 0 0 1.5%;
    transition: all .1s ease-in-out;
}

.inner-text p {
    margin: 1.5% 0 0;
    transition: all .1s ease-in-out;
}

.inner-text {
    margin: 4.5% 0;
    padding: 3% 0 0;
    position: relative;
}

.inner-text:before {
    content: '';
    position: absolute;
    display: block;
    width: 60px;
    height: 6px;
    background: #1A1A1A;
    top: 0;
    left: 0;
}

.card-inner h4 {
    font-size: 24px;
    line-height: 29px;
    margin: 0;
    transition: all .1s ease-in-out;
}

.row-big-card .cont:hover .card-img img, 
.row-big-card .cont:hover .card-img div {
    clip-path: polygon(0 0, 92% 0, 100% 100%, 8% 100%);
}

span.cta-card {
    display: inline-block;
    font-size: 18px;
    line-height: 82px;
    text-transform: uppercase;
    padding: 0 13%;
    border: 1px solid #1A1A1A;
    color: #1A1A1A;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    transition: all .2s ease-in-out;
}

a.card-link:hover span.cta-card {
    background: #1A1A1A;
    color: white;
}

.card-layout {
    display: flex;
    flex-direction: row; /* Default layout */
}

.card-layout.reverse {
    flex-direction: row-reverse; /* Reverse layout for the second card */
}

/* Additional adjustments for responsiveness */
@media (max-width: 768px) {
    .card-layout {
        flex-direction: column; /* Stack on smaller screens */
    }
    .card-inner, .card-img {
        width: 100%; /* Full width for inner and image sections */
    }
    .card-img {
        padding-left: 0; /* Remove padding on mobile */
    }
    .row-big-card .cont .card-inner {
        padding: 20px; /* Adjust padding for mobile */
    }
}


/* Media Queries */
@media all and (max-width: 1774px) {
    .card-inner h3 {
        font-size: calc(30px + (76 - 30)*(100vw - 1100px)/(1774 - 1100));
        line-height: calc(36px + (82 - 36)*(100vw - 1100px)/(1774 - 1100));
    }
    .card-inner h4 {
        font-size: calc(20px + (24 - 20)*(100vw - 1100px)/(1774 - 1100));
        line-height: calc(22px + (29 - 22)*(100vw - 1100px)/(1774 - 1100));
    }
    .card-inner p {
        font-size: calc(16px + (19 - 16)*(100vw - 1100px)/(1774 - 1100));
        line-height: calc(20px + (33 - 20)*(100vw - 1100px)/(1774 - 1100));
    }
    span.cta-card {
        font-size: calc(16px + (18 - 16)*(100vw - 1100px)/(1774 - 1100));
        line-height: calc(45px + (82 - 45)*(100vw - 1100px)/(1774 - 1100));
    }
    .row-big-card .cont .card-inner {
        padding-left: calc(40px + (80 - 40)*(100vw - 1100px)/(1774 - 1100));
        padding-top: calc(20px + (40 - 20)*(100vw - 1100px)/(1774 - 1100));
    }
}