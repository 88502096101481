@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

* {
  outline: none;
  box-sizing: border-box;
}

:root {
  --body-bg-color: #e5ecef;
  --theme-bg-color: #fafafb;
  --body-font: "Poppins", sans-serif;
  --body-color: #2f2f33;
  --active-color: #0162ff;
  --active-light-color: #e1ebfb;
  --header-bg-color: #fff;
  --search-border-color: #efefef;
  --border-color: #d8d8d8;
  --alert-bg-color: #e8f2ff;
  --subtitle-color: #83838e;
  --inactive-color: #f0f0f0;
  --placeholder-color: #9b9ba5;
  --time-button: #fc5757;
  --level-button: #5052d5;
  --button-color: #fff;
}

.dark-mode {
  --body-bg-color: #1d1d1d;
  --theme-bg-color: #13131a;
  --header-bg-color: #1c1c24;
  --alert-bg-color: #292932;
  --body-color: #fff;
  --inactive-color: #292932;
  --time-button: #fff;
  --level-button: #fff;
  --active-light-color: #263d63;
  --border-color: #26262f;
  --search-border-color: #26262f;
}

::placeholder {
  color: var(--placeholder-color);
}

img {
  max-width: 100%;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

body {
  background-color: var(--body-bg-color);
  font-family: var(--body-font);
  font-size: 15px;
  color: var(--body-color);
}

.dark-light svg {
  margin-right: 8px;
  width: 22px;
  cursor: pointer;
  fill: transparent;
  transition: 0.5s;
}

.dark-mode .dark-light svg {
  fill: #ffce45;
  stroke: #ffce45;
}

.dark-mode .job-card svg {
  box-shadow: none;
}

.dark-mode .search.item {
  color: var(--body-color);
  border-color: var(--body-color);
}

.dark-mode .search-location svg,
.dark-mode .search-job svg,
.dark-mode .search-salary svg {
  color: var(--body-color);
}

.dark-mode .detail-button {
  background-color: var(--inactive-color);
  color: var(--subtitle-color);
}

.job {
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  margin: 0 auto;
  overflow: hidden;
  background-color: var(--theme-bg-color);
}

.logo {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
}

.logo svg {
  width: 24px;
  margin-right: 12px;
}

.header {
  display: flex;
  align-items: center;
  transition: box-shadow 0.3s;
  flex-shrink: 0;
  padding: 0 40px;
  white-space: nowrap;
  background-color: var(--header-bg-color);
  height: 60px;
  width: 100%;
  font-size: 14px;
  justify-content: space-between;
}

.header-menu a {
  text-decoration: none;
  color: var(--body-color);
  font-weight: 500;
}

.header-menu a:hover {
  color: var(--active-color);
}

.header-menu a:not(:first-child) {
  margin-left: 30px;
}

.header-menu a.active {
  color: var(--active-color);
}

.user-settings {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.user-settings svg {
  width: 20px;
  color: #94949f;
}

.user-menu {
  position: relative;
  margin-right: 8px;
  padding-right: 8px;
  border-right: 2px solid #d6d6db;
}

.user-menu:before {
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  border: 2px solid var(--header-bg-color);
  right: 6px;
  top: -1px;
  background-color: var(--active-color);
}

.user-profile {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  scroll-behavior: smooth;
  padding: 30px 40px;
  overflow: auto;
}

.search-menu {
  height: 56px;
  white-space: nowrap;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  background-color: var(--header-bg-color);
  border-radius: 8px;
  width: 100%;
  padding-left: 20px;
}

.search-menu div:not(:last-of-type) {
  border-right: 1px solid var(--search-border-color);
}

.search-bar {
  height: 55px;
  width: 100%;
  position: relative;
}

.search-bar input {
  width: calc(100% - 100px);
  height: 100%;
  display: block;
  background-color: transparent;
  border: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.966 56.966' fill='%230162ff'%3e%3cpath d='M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: 0 50%;
  padding: 0 25px 0 40px;
}

.search-location,
.search-job,
.search-salary {
  display: flex;
  align-items: center;
  width: calc(33.333% - 10px);
  font-size: 14px;
  font-weight: 500;
  padding: 0 25px;
  height: 100%;
}

.search-location input,
.search-job input,
.search-salary input {
  width: 100%;
  height: 100%;
  display: block;
  background-color: transparent;
  border: none;
}

.search-location svg,
.search-job svg,
.search-salary svg {
  margin-right: 8px;
  width: 18px;
  color: var(--active-color);
  flex-shrink: 0;
}

.search-button {
  background-color: var(--active-color);
  height: 55px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  padding: 0 15px;
  border-radius: 0 8px 8px 0;
  color: var(--button-color);
  cursor: pointer;
}

.search.item {
  position: absolute;
  top: 10px;
  left: 25px;
  font-size: 13px;
  color: var(--active-color);
  border: 1px solid var(--search-border-color);
  padding: 8px 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.search.item svg {
  width: 12px;
  margin-left: 5px;
}

.search.item:last-child {
  left: 175px;
}

.main-container {
  display: flex;
  flex-grow: 1;
  padding-top: 30px;
}

.search-type {
  width: 270px;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-shrink: 0;
}

.alert {
  background-color: var(--alert-bg-color);
  padding: 24px 18px;
  border-radius: 8px;
}

.alert-title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.alert-subtitle {
  font-size: 13px;
  color: var(--subtitle-color);
  line-height: 1.6em;
  margin-bottom: 20px;
}

.alert input {
  width: 100%;
  padding: 10px;
  display: block;
  border-radius: 6px;
  background-color: var(--header-bg-color);
  border: none;
  font-size: 13px;
}

.search-buttons {
  border: none;
  color: var(--button-color);
  background-color: var(--active-color);
  padding: 8px 10px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 600;
  margin-top: 14px;
}

.job-wrapper {
  padding-top: 20px;
}

.job-time {
  padding-top: 20px;
}

.job-time-title {
  font-size: 14px;
  font-weight: 500;
}

.type-container {
  display: flex;
  align-items: center;
  color: var(--subtitle-color);
  font-size: 13px;
}

.type-container label {
  margin-left: 2px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.type-container + .type-container {
  margin-top: 10px;
}

.job-number {
  margin-left: auto;
  background-color: var(--inactive-color);
  color: var(--subtitle-color);
  font-size: 10px;
  font-weight: 500;
  padding: 5px;
  border-radius: 4px;
}

.job-style {
  display: none;
}

.job-style + label:before {
  content: "";
  margin-right: 10px;
  width: 16px;
  height: 16px;
  border: 1px solid var(--subtitle-color);
  border-radius: 4px;
  cursor: pointer;
}

.job-style:checked + label:before {
  background-color: var(--active-color);
  border-color: var(--active-color);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3e%3cpath d='M20 6L9 17l-5-5'/%3e%3c/svg%3e");
  background-position: 50%;
  background-size: 14px;
  background-repeat: no-repeat;
}

.job-style:checked + label + span {
  background-color: var(--active-light-color);
  color: var(--active-color);
}

.searched-jobs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 40px;
}

.searched-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  animation: slideY 0.6s both;
}

.searched-show {
  font-size: 19px;
  font-weight: 600;
}

.searched-sort {
  font-size: 14px;
  color: var(--subtitle-color);
}

.searched-sort .post-time {
  font-weight: 600;
  color: var(--subtitle-color);
}

.searched-sort .menu-icon {
  font-size: 9px;
  color: var(--placeholder-color);
  margin-left: 6px;
}

.job-cards {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  animation: slideY 0.6s both;
}

.job-card {
  padding: 20px 16px;
  background-color: var(--header-bg-color);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
}

.job-card:hover {
  transform: scale(1.02);
}

.job-card svg {
  width: 46px;
  padding: 10px;
  border-radius: 8px;
}

.job-card-title {
  font-weight: 600;
  margin-top: 16px;
  font-size: 14px;
}

.job-card-subtitle {
  color: var(--subtitle-color);
  font-size: 13px;
  margin-top: 14px;
  line-height: 1.6em;
}

.job-card-header {
  display: flex;
  align-items: flex-start;
}

.overview-card:hover {
  background: #2b2ecf;
  transition: none;
  transform: scale(1);
}

.overview-card svg {
  box-shadow: none;
}

.job-overview-buttons .search-buttons.time-button,
.job-overview-buttons .search-buttons.level-button {
  background-color: #575ad8;
  color: #fff;
}

.job-card-title,
.job-stat {
  color: #000000;
}

.job-card-subtitle,
.job-day {
  color: #000000;
}

.overview-wrapper .heart {
  color: #fff;
  border-color: #fff;
}

.overview-wrapper .heart:hover {
  fill: red;
  stroke: red;
  transform: scale(1.1);
}

.detail-button {
  background-color: var(--active-light-color);
  color: var(--active-color);
  font-size: 11px;
  font-weight: 500;
  padding: 6px 8px;
  border-radius: 4px;
  margin-left: 4px;
}

.job-card-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 4px;
}

.card-buttons,
.card-buttons-msg {
  padding: 10px;
  width: 100%;
  font-size: 12px;
  cursor: pointer;
}

.card-buttons-msg {
  background-color: var(--inactive-color);
  color: var(--subtitle-color);
}

.menu-dot {
  background-color: var(--placeholder-color);
  box-shadow: -6px 0 0 0 var(--placeholder-color), 6px 0 0 0 var(--placeholder-color);
  width: 4px;
  height: 4px;
  border: 0;
  padding: 0;
  border-radius: 50%;
  margin-left: auto;
  margin-right: 8px;
}

.header-shadow {
  box-shadow: 0 4px 20px rgba(88, 99, 148, 0.17);
  z-index: 1;
}

.job-overview {
  display: flex;
  flex-grow: 1;
}

.job-overview-cards {
  display: flex;
  flex-direction: column;
  width: 330px;
  height: 100%;
  flex-shrink: 0;
}

.job-overview-card + .job-overview-card {
  margin-top: 20px;
}

.job-overview-buttons {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.job-overview-buttons .search-buttons {
  background-color: var(--inactive-color);
  font-size: 11px;
  padding: 6px 8px;
  margin-top: 0;
  font-weight: 500;
}

.job-overview-buttons .search-buttons.time-button {
  color: var(--time-button);
  margin-right: 8px;
}

.job-overview-buttons .search-buttons.level-button {
  color: var(--level-button);
}

.job-overview-buttons .job-stat {
  color: var(--active-color);
  font-size: 12px;
  font-weight: 500;
  margin-left: auto;
}

.job-overview-buttons .job-day {
  color: var(--subtitle-color);
  font-size: 12px;
  margin-left: 8px;
  font-weight: 500;
}

.overview-wrapper {
  display: flex;
  align-items: center;
}

.overview-wrapper svg:first-child {
  width: 42px;
  margin-right: 10px;
}

.overview-wrapper .heart {
  background: none;
  box-shadow: none;
  width: 24px;
  padding: 4px;
  color: var(--subtitle-color);
  border: 1px solid var(--border-color);
  margin-left: auto;
  margin-bottom: auto;
}

.job-explain {
  background-color: var(--header-bg-color);
  margin-left: 40px;
  border-radius: 0 0 8px 8px;
}

.job-bg {
  border-radius: 8px 8px 0 0;
  object-fit: cover;
  width: 100%;
  height: 180px;
  transition: 0.3s;
  position: relative;
}

.job-logos {
  margin-top: -30px;
  position: relative;
  margin-bottom: -36px;
  padding: 0 20px;
}

.job-logos svg {
  width: 66px;
  padding: 12px;
  background-color: #fff;
  border-radius: 10px;
  border: 4px solid var(--header-bg-color);
}

.job-title-wrapper {
  display: flex;
  align-items: center;
}

.job-title-wrapper .job-card-title {
  font-size: 20px;
  margin-top: 0;
  font-weight: 600;
}

.job-action {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.job-action svg {
  width: 32px;
  border: 1px solid var(--border-color);
  color: var(--subtitle-color);
  border-radius: 8px;
  padding: 6px;
}

.job-explain-content {
  padding: 50px 25px 30px;
}

.job-subtitle-wrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.job-subtitle-wrapper .posted {
  margin-left: auto;
}

.job-subtitle-wrapper .company-name {
  color: var(--active-color);
  font-weight: 600;
  font-size: 14px;
}

.job-subtitle-wrapper .comp-location,
.job-subtitle-wrapper .posted {
  color: var(--subtitle-color);
  font-size: 12px;
  font-weight: 500;
}

.job-subtitle-wrapper .comp-location {
  position: relative;
  margin-left: 10px;
}

.job-subtitle-wrapper .comp-location:before {
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: var(--placeholder-color);
  top: 49%;
  left: -8px;
  position: absolute;
}

.job-subtitle-wrapper .app-number {
  font-size: 10px;
  color: var(--placeholder-color);
  margin-left: 10px;
}

.job-subtitle-wrapper .app-number svg {
  margin-right: 5px;
}

.job-overview-card {
  background-color: var(--header-bg-color);
  padding: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.job-overview-card svg {
  width: 36px;
  color: var(--subtitle-color);
}

.job-overview-card .job-overview-title {
  font-size: 16px;
  color: var(--body-color);
}

.job-overview-card .job-overview-number {
  font-size: 24px;
  font-weight: 600;
}

@media screen and (max-width: 1180px) {
  .job {
    padding: 0 15px;
  }
  .header {
    padding: 0 20px;
  }
  .wrapper {
    padding: 20px;
  }
  .job-card-buttons {
    flex-wrap: wrap;
    margin-top: 10px;
  }
  .card-buttons {
    margin-top: 10px;
  }
  .job-card svg {
    width: 30px;
  }
  .job-overview-buttons .search-buttons {
    padding: 6px;
    font-size: 10px;
  }
}

@media screen and (max-width: 850px) {
  .search-type {
    display: none;
  }
  .search-menu {
    display: none;
  }
  .search-bar {
    margin-top: 10px;
  }
  .searched-jobs {
    padding-left: 0;
  }
  .searched-bar {
    flex-direction: column;
  }
  .searched-show {
    margin-top: 20px;
  }
}

@media screen and (max-width: 650px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }
  .user-settings {
    margin-top: 10px;
  }
  .user-profile {
    margin-right: 0;
  }
  .header-menu {
    flex-direction: column;
    margin-top: 20px;
  }
  .search-menu {
    justify-content: center;
    padding-left: 0;
    margin-top: 20px;
  }
  .search-menu div:not(:last-of-type) {
    border-right: none;
  }
  .search-bar input {
    width: 100%;
  }
  .search-bar {
    position: static;
  }
  .search-bar input,
  .search-location,
  .search-job,
  .search-salary {
    width: 100%;
    margin-bottom: 10px;
    padding: 12px 20px;
  }
  .search-button {
    width: 100%;
    border-radius: 8px;
  }
  .searched-sort {
    margin-top: 20px;
  }
}

@media screen and (max-width: 420px) {
  .header-menu a:not(:first-child) {
    margin-left: 0;
    margin-top: 10px;
  }
  .search-menu div {
    width: calc(33.333% - 20px);
  }
  .search-bar input,
  .search-location,
  .search-job,
  .search-salary {
    padding: 12px 10px;
  }
  .searched-bar {
    flex-direction: column;
    align-items: flex-start;
  }
  .searched-sort {
    margin-top: 10px;
  }
  .job-card-buttons {
    flex-direction: column;
    align-items: flex-start;
  }
}

@keyframes slideY {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
