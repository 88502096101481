/* Pagenotfound.css */

/* General styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1 {
  font-size: 3rem;
  text-align: center;
  margin-top: 2rem;
}

.cloak__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.cloak__container {
  position: relative;
  width: 200px;
  height: 200px;
}

.cloak {
  width: 100%;
  height: 100%;
  background: #ccc;
  border-radius: 50%;
  animation: spin 3s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.info {
  text-align: center;
  padding: 2rem;
}

.info h2 {
  font-size: 2rem;
}

.info p {
  font-size: 1rem;
  margin: 1rem 0;
}

.info a {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
}

.info a:hover {
  background: #0056b3;
}

/* Responsive styles */
@media (max-width: 768px) {
  h1 {
    font-size: 2.5rem;
  }

  .cloak__container {
    width: 150px;
    height: 150px;
  }

  .info h2 {
    font-size: 1.5rem;
  }

  .info p {
    font-size: 0.9rem;
  }

  .info a {
    padding: 0.4rem 0.8rem;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 2rem;
  }

  .cloak__container {
    width: 120px;
    height: 120px;
  }

  .info h2 {
    font-size: 1.2rem;
  }

  .info p {
    font-size: 0.8rem;
  }

  .info a {
    padding: 0.3rem 0.6rem;
  }
}
