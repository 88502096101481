.card-custom {
  overflow: hidden;
  min-height: 450px;
  box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
}

.card-custom-img {
  height: 200px;
  min-height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-color: inherit;
}

/* First border-left-width setting is a fallback */
.card-custom-img::after {
  position: absolute;
  content: '';
  top: 161px;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-top-width: 40px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-left-width: 545px;
  border-left-width: calc(575px - 5vw);
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: inherit;
}

.card-custom-avatar img {
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
  position: absolute;
  top: 100px;
  left: 1.25rem;
  width: 100px;
  height: 100px;
}

.card-scrollable {
  overflow-y: auto;
  max-height: calc(450px - 250px); /* Adjust this value as needed */
}

/* Custom Scrollbar Styles */
.card-scrollable::-webkit-scrollbar {
  width: 8px;
}

.card-scrollable::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

.card-scrollable::-webkit-scrollbar-thumb {
  background-color: #AEC6CF; /* Blue color for the scrollbar */
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.card-scrollable::-webkit-scrollbar-thumb:hover {
  background: #AEC6CF; /* Darker blue on hover */
}
